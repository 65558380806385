import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { withPrefix } from "gatsby";

function SEO({
  description,
  lang,
  meta,
  keywords,
  title,
  img,
  robots,
  twitterCardContent,
}) {

  const metaDescription = description || "Gain a better understanding of your benefits and ask our experts questions at our upcoming virtual event. The two-day event includes live instructor-led classes, including five evening classes on the first day, and Ask the Experts rooms hosted by our CalPERS programs and partnering organizations.";
  const metaTags = [  ];

  if (title) {
    metaTags.push({
      property: "og:title",
      content: title,
    });
    metaTags.push({
      name: "twitter:title",
      content: title,
    });
  }

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang,
        }}
        title={title ? title : "CalPERS"}
        titleTemplate={`%s`}
      >
        {metaDescription ? <meta name="description" content={metaDescription} /> : null}
        {metaDescription ? (
          <meta name="og:description" content={metaDescription} />
        ) : null}
        {metaDescription ? (
          <meta name="twitter:description" content={metaDescription} />
        ) : null}

        {img ? <meta name="og:image" content={img} /> : null}
        {img ? <meta name="og:image:url" content={`${img}`} /> : null}
        {img ? <meta name="twitter:image" content={`${img}`} /> : null}

        {keywords ? <meta name="keywords" content={keywords} /> : null}
        
   <meta name="twitter:card" content={twitterCardContent ? twitterCardContent : " summary"} />  
      <meta name="og:type" content="business.business" />  
      

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${withPrefix("/")}favicon/apple-touch-icon.png`}
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix("/")}favicon/favicon-48x48.png`}
          sizes="48x48"
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix("/")}favicon/favicon-72x72.png`}
          sizes="72x72"
        />
         <link
          rel="icon"
          type="image/png"
          href={`${withPrefix("/")}favicon/favicon-128x128.png`}
          sizes="128x128"
        />
        
        
        <link
          rel="mask-icon"
          href={`${withPrefix("/")}img/safari-pinned-tab.svg`}
          color="#ff4400"
        />
        <meta name="theme-color" content="#fff" />
      </Helmet>
    </>
  );
}

SEO.defaultProps = {
  lang: "en",
  meta: [],
  keywords: [],
  robots: "index",
};

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.array,
  keywords: PropTypes.arrayOf(PropTypes.string),
  title: PropTypes.string.isRequired,
  robots: PropTypes.string,
  twitterCardContent: PropTypes.string,
};

export default SEO;