import React from 'react';
import { XCircleIcon } from "@heroicons/react/solid";

function ErrorComponent({message}) {
    return (
        <div className="text-crimson-500 flex items-center my-2 font-semibold">
        <XCircleIcon className="w-6 h-6 mr-2 flex-shrink-0"/> {message}
        </div>
    );
}

export default ErrorComponent;