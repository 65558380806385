const configData = {
    APP: {

        URL: process.env.GATSBY_APP_URL,
        APP_NAME: process.env.GATSBY_APP_APP_NAME,

        //from Insight
        ENV: process.env.REACT_APP_ENV || '',
        AUTO_LOGOUT_MINS: parseInt(process.env.REACT_APP_AUTO_LOGOUT_MINS) || 10,
        CONTEXT_PATH: process.env.REACT_APP_APP_CONTEXT_PATH || '',
        VERSION_NUMBER: (process.env.REACT_APP_BUILD_NUM && parseInt(process.env.REACT_APP_BUILD_NUM)) || null,
        FEATURES:
            {
                FEATURE_1: process.env.REACT_APP_APP_FEATURES_VERSION_CHECK === 'true',
            }
    },
   
    API: {
        MICROSITE: {
            URL: process.env.GATSBY_API_MICROSITE_PROTOCOL + process.env.GATSBY_API_MICROSITE_HOST +  process.env.GATSBY_API_MICROSITE_CONTEXT,
            HOST: process.env.GATSBY_API_MICROSITE_HOST,
            PROTOCOL: process.env.GATSBY_API_MICROSITE_PROTOCOL,
            CONTEXT_PATH: process.env.GATSBY_API_MICROSITE_CONTEXT,
        },
        CEC:{
            EVENT_ID: process.env.GATSBY_API_CEC_EVENT_ID,
            URL: process.env.GATSBY_API_CEC_PROTOCOL + process.env.GATSBY_API_CEC_HOST +  process.env.GATSBY_API_CEC_CONTEXT,
            HOST: process.env.GATSBY_API_CEC_HOST,
            PROTOCOL: process.env.GATSBY_API_CEC_PROTOCOL,
            CONTEXT_PATH: process.env.GATSBY_API_CEC_CONTEXT,
        },
        GOOGLE_ANALYTICS: {
            G_TAG: process.env.REACT_APP_API_GOOGLE_ANALYTICS_G_TAG
        },
        FETCH_TIMOUT_SECONDS: parseInt(process.env.REACT_APP_API_FETCH_TIMOUT_SECONDS) || 10
    },
    DEVELOPMENT: {
    }
};

// function config() {
//     // Add common config values here
//     //MAX_ATTACHMENT_SIZE: 5000000,
//     return configData
// };

module.exports = {...configData}