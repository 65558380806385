import React, { useState, useEffect } from "react";
import axios from "axios";
import jwt from "jwt-decode";
import { useLocation } from "@reach/router";
import { navigate } from "gatsby";
import config from "../config"
import { returnDateToUseForCECRequests } from "../helpers/dates";
import { useLocalStorage} from '../hooks/useLocalStorage'
import { useLocalStorage as useMantineLocalStorage} from '@mantine/hooks';

const debugged = false;
const logger = (message, focus) =>
  debugged ? console.log(focus, ": ", message) : "";

export const AuthContext = React.createContext({
  token: null,
  user:null,
  eventInfo: null,
  registerInfoForLoggedInUser: null,
  loginState: 1,
  loggingInStatus: null,
  loginErrors: null,
  schedulePref: 0,
  spinner:false,
  registeredEmail: null,
  isUserLoggedIn: () => {
    return false;
  },
  requestPinCode: () => {},
  changeLoginState:  () => {},
  changeSchedulePref:  () => {},
  changeRegisteredEmail:  () => {}, 
  login: () => {},
  logout: () => {},
  loginMagicLink: () => {},
  fetchEventIDAndDates: () => {},
  fetchRegisterInfoForLoggedInUser: () => {}
});

export const AuthProvider = ({ children }) => {
  
  const [eventStartDate, setEventStartDate] = useMantineLocalStorage({ key: 'eventStartDate' });
  const [eventEndDate, setEventEndDate] = useMantineLocalStorage({ key: 'eventEndDate' });


  // had to set it to default or check cuz first render doesn't get to useEffect()
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);

  const [registerInfoForLoggedInUser, setRegisterInfoForLoggedInUser] = useState(null);

  const [eventInfo, setEventInfo] = useLocalStorage('eventInfo', '')
  const [registration, setRegistration] = useLocalStorage('registration', '')
  const [userLocalStorage, setUserLocalStorage] = useLocalStorage('user', '')

  
  const [loggingInStatus, setLoggingInStatus] = useState("Log in");
  const [spinner, setSpinner] = useState(false);

  const [pinCodeRequestSent, setPinCodeRequestSent] = useState(false);
  const [errorSendingPinCode, setErrorSendingPinCode] = useState(false);
  const [schedulePref, setSchedulePref] = useState(0);

  
  const [registeredEmail, setRegisteredEmail] = useState('');

  
 
  const [loginState, setLoginState] = useState(1)

  const [errors, setErrors] = useState(([]));
  const location = useLocation();
  const logout = () => {
    setUser({ name: "" });
    setToken(null);
    setRegisterInfoForLoggedInUser(null) 

 

    window && window.localStorage.removeItem("selectedClassDay");  
    window && window.localStorage.removeItem("careerStageSelection"); 
    window && window.localStorage.removeItem("token");
    window && window.localStorage.removeItem("user");
    window && window.localStorage.removeItem("eventInfo");
    window && window.localStorage.removeItem("registration");

    window && window.localStorage && window.localStorage.removeItem("pref");

    navigate("/");
  };

  
  const changeRegisteredEmail = (email) => {
    window.localStorage.setItem("cbeeeventreg", email); 

    
    console.log("set newly registered email to state:", email)
    setRegisteredEmail(email)
  };

  const changeLoginState = (state) => {
    setLoginState(state)
  };


  const changeSchedulePref = (state) => {
    console.log('Changed selected tab to:', state)
    window.localStorage.setItem("pref",state);

    setSchedulePref(state)

  };


  function checkStorageSetUser() {
    let token = null;
    let user = { name: "" };
    let pref = 0;
    token = localStorage.getItem("token");
    user = userLocalStorage
    pref = localStorage.getItem("pref");

    
    window.localStorage.getItem("token");
    window.localStorage.getItem("pref");


    if (!token) return { token: null, decoded: { exp: Date.now() / 1000 } };
    if (token) setToken(token);
    if (userLocalStorage) setUser(userLocalStorage);
    if (pref) setSchedulePref(pref);
    if (registration) {
      setRegisterInfoForLoggedInUser(registration)
    }


    return { token, decoded: jwt(token) };
  }
  // returns boolean
  function tokenDidNotExpire(decodedToken) { 
    // expiration must be greater than current time
    return true  
  }
  function tokenExpired(decodedToken) {
    return !tokenDidNotExpire(decodedToken);
  }

  useEffect(() => {
    const { token, decoded } = checkStorageSetUser();
    // need to move this out of useEffect to prevent unnecessary component mounting
    // if(typeof window !== undefined) run this code
    // basically window is not defined in nodejs
    // needs to boot only on admin pages+
    let tokenValidator;
    // admin pages
    if (location.pathname.match(/(admin\/([a-zA-Z0-9\-\\])+)/g) !== null) {
      // token exists
      console.log("token", token);
      if (token != null) {
        tokenValidator = window.setInterval(() => {
          // validate token
          if (tokenExpired(decoded)) logout();
          // would be smart to run this interval right when the token expires and has not been updated and the counter has not been updated; every 60s/1 min would work
          // but would present some bugs for some people
        }, Date.now() / 1000 - decoded.exp);
      } else {
        console.log("kicking user");
        // else boot user from admin page
        logout();
      }
    }
    // cleanup
    return () => {
      if (tokenValidator) clearInterval(tokenValidator);
    };
  }, []);


  function getEventInfo(setEventInfo) {
    axios.get(config.API.CEC.URL + '/cec-classmgmt/event/future-virtual-cbe?eventIdOnly=true&startDate=' + returnDateToUseForCECRequests()).then(function (response) {
      setEventInfo(response.data)
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  }

  function returnEventInfo() {
     return axios.get(config.API.CEC.URL + '/cec-classmgmt/event/future-virtual-cbe?eventIdOnly=true&startDate=' + returnDateToUseForCECRequests())
  }

  function getLoggedInUserRegisteredInfo(setRegisterInfoForLoggedInUser, token) {

    axios.get(config.API.CEC.URL + '/cec-classmgmt/event/future-virtual-cbe?eventIdOnly=true&startDate=' + returnDateToUseForCECRequests())
    .then(function (response) {
     setEventInfo(response.data)

     axios.get(config.API.MICROSITE.URL + `/v1/event/registration?token=${token}&eventId=${response.data.eventId}`)
     .then(function (response) {
     
      setRegistration(response.data)
      setRegisterInfoForLoggedInUser(response.data)


         //update user item with updated reg code and event reg id
         //only update if user is set, since we dont need to update on login since thats coming fresh from login service
         if (response.data.cec_confirmation_code && response.data.cec_event_regstrtn_id && user) {
          let updatedUserInfo = { ...user, cec_event_regstrtn_id: response.data.cec_event_regstrtn_id, cec_confirmation_code: response.data.cec_confirmation_code, career_stage_code: response.data.career_stage_code }
          
          setUserLocalStorage({ ...updatedUserInfo })
        
          setUser({ ...updatedUserInfo });

         }
     })
 

    })


    
  }

  function isUserLoggedIn() {
 
    try {
      logger("checking for token", "isUserLoggedIn");
      let token = null;
      token = window.localStorage.getItem("token");
      if (!token) {
        logger(
          "no token found; boot user from private pages",
          "isUserLoggedIn"
        );
        return false;
      } // boot user
      const decoded = jwt(token);
      return tokenDidNotExpire(decoded);
    } catch (error) {
      logger(error, "isUserLoggedIn");
      return false;
    }
  }



  return (
    <AuthContext.Provider
      value={{
        token,
        user,
        registerInfoForLoggedInUser,
        loggingInStatus: loggingInStatus,
        loginErrors: errors,
        spinner: spinner,
        schedulePref: schedulePref,
        changeSchedulePref: changeSchedulePref,
        isUserLoggedIn: isUserLoggedIn,
        requestPinCode:  (username) => {
          async function requestPinCodeFunc() {
            setSpinner(true)
            setErrors([]); 

            //get the event info from service to get start dates for email 
            returnEventInfo().then(function (response) {
                setEventEndDate(response.data.eventEndDate)
                setEventStartDate(response.data.eventStartDate)
 
                const params = new URLSearchParams();
                params.append('email', username.toLowerCase());
                params.append('eventStartDate', response && response.data && response.data.eventStartDate ? response && response.data && response.data.eventStartDate : "2023-02-08T17:00:00-08:00");
                params.append('eventEndDate', response && response.data && response.data.eventEndDate ? response && response.data && response.data.eventEndDate : "2023-02-09T17:00:00-08:00");
 
    
                axios.post(config.API.MICROSITE.URL + '/v1/auth/request-login/', params)
                .then((response) => { 
                    if (response.status == 400) { 
                      setSpinner(false)
                      setErrors([{ message: 'No registration is found for this email. Try a different email or register.' }]);
                    } else {
                      setSpinner(false)
                      setLoginState(2)
                    }
                })
                .catch((error) => { 
                  setErrors([{ message: 'No registration is found for this email. Try a different email or register.' }]);
    
                  setPinCodeRequestSent(false);
                  setErrorSendingPinCode(true)
                  setSpinner(false)
                }); 




            })
            .catch((error) => {
              console.error('Error:', error);
            });

       
             
         
          }

          requestPinCodeFunc(); 
          
        },
        loginMagicLink: (email, magicCode) => {

          async function processMagicLink() {
            fetch(config.API.MICROSITE.URL + '/v1/auth/login/', {
                method: 'POST',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                body: new URLSearchParams({
                  'email': email.toLowerCase(),
                  'magicCode': magicCode ,
                })
            }).then(response => response.json())
              
              .then(data =>  {             
              
              window.localStorage.setItem("token", data.token);

              setUserLocalStorage(data.userInfo);
             

              setToken(data.token);
              setUser({...data.userInfo});


              setTimeout(() => {
                navigate("/account/dashboard");
                setSpinner(false)
              }, 700);

              
              
            })
             
          }

          processMagicLink();
        },
        fetchEventIDAndDates: () => {
           getEventInfo(setEventInfo);
        },
        fetchRegisterInfoForLoggedInUser: () => {
          getLoggedInUserRegisteredInfo(setRegisterInfoForLoggedInUser, token, 1003086);
       },

        login: (username, pin) => {
          async function processLogin() {
            setErrors([]);
            setLoggingInStatus("Logging you in");

            if(!pin){
              setErrors([{ message: 'Enter Pin Code to continue.' }]);
                setLoggingInStatus("Login")
                setSpinner(false)
            } else{
              setSpinner(true)

              fetch(config.API.MICROSITE.URL + '/v1/auth/login/', {
                  method: 'POST',
                  headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                  body: new URLSearchParams({
                      'email': username.toLowerCase(),
                      'pin': pin 
                  })
              }).then(response => response.json())
  
                .then(data => {
   
  
                   
  
                  getLoggedInUserRegisteredInfo(setRegisterInfoForLoggedInUser, data.token)
  
              getEventInfo(setEventInfo);
  
                  setTimeout(() => {
  
                    window.localStorage.setItem("token", data.token);
  
                   setUserLocalStorage(data.userInfo)
                 
                   setToken(data.token);
                   setUser(data.userInfo);
  
                    navigate("/account/dashboard");
                    setSpinner(false)
                  }, 1000);
  
  
  
                }).catch(error => {
                  console.log("error", error)
                  setErrors([{ message: 'Wrong Pin Code entered. Check your email and try again.' }]);
                  setLoggingInStatus("Login")
                  setSpinner(false)
                }, 500);
            }

            

              
              
            }

          processLogin();
          
        },
        logout: logout,
        loginState: loginState,
        changeLoginState: changeLoginState,
        changeRegisteredEmail: changeRegisteredEmail, 
        registeredEmail: registeredEmail
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
