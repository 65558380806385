import React, {  useEffect, useRef } from "react";


const dayjs = require("dayjs");
const utc = require('dayjs/plugin/utc')
const timezone = require('dayjs/plugin/timezone') // dependent on utc plugin

dayjs.extend(utc)
dayjs.extend(timezone)

export const format = "h:mm"

export function returnReadableDateRange(start,end) { 
    return dayjs(start).format("MM/DD") + " - " + dayjs(end).format("MM/DD/YYYY")
}


export function returnAMPMBasedOnStartEndDate(startDate, endDate, timezone){
  if(dayjs(startDate).tz(timezone).format("H") > 11 && dayjs(endDate).tz(timezone).format("H") > 11) {
      return [" ", " p.m."]
  }
  else if(dayjs(startDate).tz(timezone).format("H") < 12 && dayjs(endDate).tz(timezone).format("H") < 12) {
    return [" ", " a.m."]
}  else {
  return [" a.m.", " p.m."]
}
}

export function returnAMPMBasedOnDate(date, timezone){
  if(dayjs(date).tz(timezone).format("H") >= 12) {
      return "p.m."
  }else{
    return "a.m."
  }
}
 
export function returnDateToUseForCECRequests() {

  let dateToUseForCECRequests = dayjs(new Date(Date.now() - 86400000 * 7)).format('YYYY-MM-DDThh:mm:ss');
  return dateToUseForCECRequests + "Z";
}

export function hasEventEnded (date) {
 if (date === null || date === undefined) {
   return false
 } 
 
 let diff = dayjs(date ? date : "2023-02-09T16:45:00-08:00").diff(dayjs(), 'seconds') 
 
 if(diff < 0) {
   return true
 } else {
  return false
 }

}
 

export function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest function.
  useEffect(() => {
      savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
      function tick() {
          savedCallback.current();
      }
      if (delay !== null) {
          let id = setInterval(tick, delay);
          return () => clearInterval(id);
      }
  }, [delay]);
}